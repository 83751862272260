import "./CatalogsScreen1.css";
import globalStates from '../../utils/global';
import { useContext, useEffect, useMemo, useState } from "react";
import { AppNavbar } from "../../components";
import { productBlackCumin, productBlackPepper, productBlackPepperPowder, productChiliFlakes, productChiliPowder, productCinnamonPowder, productCinnamonStick, productCloves, productDryChilli, productMahogany, productNutmeg, productNutmegPapua, productOregano, productSesame, productTurmericPowder, productWhiteCardamom, productWhitePepper } from "../../assets";


const CatalogsScreen1 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const defaultLanguage = globalContext && globalContext.defaultLanguage;

    const productListEn = useMemo(()=>{
        return [
        {
            name: "Black Pepper",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole",
            imgSrc: productBlackPepper
        },
        {
            name: "Chili Flakes",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole",
            imgSrc: productChiliFlakes
        },
        {
            name: "Cinnamon Stick",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productCinnamonStick
        },
        {
            name: "Cloves",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productCloves
        },
        {
            name: "Dry Chilli",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole",
            imgSrc: productDryChilli
        },
        {
            name: "Mahogany",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productMahogany
        },
        {
            name: "Nutmeg",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productNutmeg
        },
        {
            name: "Nutmeg Papua",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productNutmegPapua
        },
        {
            name: "White Cardamom",
            type: ["whole"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productWhiteCardamom
        },
        {
            name: "White Pepper",
            type: ["whole/utuh"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "whole", 
            imgSrc: productWhitePepper
        },
        {
            name: "Black Pepper (Powder)",
            type: ["powder/bubuk"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "powder", 
            imgSrc: productBlackPepperPowder
        },
        {
            name: "Cinnamon (Powder)",
            type: ["powder/bubuk"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "powder", 
            imgSrc: productCinnamonPowder
        },
        {
            name: "Chili (Powder)",
            type: ["powder/bubuk"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "powder", 
            imgSrc: productChiliPowder
        },
        {
            name: "Tumeric (Powder)",
            type: ["powder/bubuk"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "powder", 
            imgSrc: productTurmericPowder
        },
        {
            name: "Black Cumin (Seed)",
            type: ["seed/biji"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "seeds", 
            imgSrc: productBlackCumin
        },
        {
            name: "Oregano (Seed)",
            type: ["seed/biji"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "seeds", 
            imgSrc: productOregano
        },
        {
            name: "Sesame (Seed)",
            type: ["seed/biji"],
            size: "250 grams or per request",
            packaging: "standing pouch with window or per request",
            notes: "seeds", 
            imgSrc: productSesame
        },

    ]
    }, []) 
    const productListIna = useMemo(()=>{
        return [
        {
            name: "Black Pepper",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole",
            imgSrc: productBlackPepper
        },
        {
            name: "Chili Flakes",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole",
            imgSrc: productChiliFlakes
        },
        {
            name: "Cinnamon Stick",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productCinnamonStick
        },
        {
            name: "Dry Chilli",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productDryChilli
        },
        {
            name: "Cloves",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productCloves
        },
        {
            name: "Mahogany",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productMahogany
        },
        {
            name: "Nutmeg",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productNutmeg
        },
        {
            name: "Nutmeg Papua",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productNutmegPapua
        },
        {
            name: "White Cardamom",
            type: ["whole"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productWhiteCardamom
        },
        {
            name: "White Pepper",
            type: ["whole/utuh"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "whole", 
            imgSrc: productWhitePepper
        },
        {
            name: "Black Pepper (Powder)",
            type: ["powder/bubuk"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "powder", 
            imgSrc: productBlackPepperPowder
        },
        {
            name: "Cinnamon (Powder)",
            type: ["powder/bubuk"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "powder", 
            imgSrc: productCinnamonPowder
        },
         {
            name: "Chili (Powder)",
            type: ["powder/bubuk"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "powder", 
            imgSrc: productChiliPowder
        },
        {
            name: "Tumeric (Powder)",
            type: ["powder/bubuk"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "powder", 
            imgSrc: productTurmericPowder
        },
        {
            name: "Black Cumin (Seed)",
            type: ["seed/biji"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "seeds", 
            imgSrc: productBlackCumin
        },
        {
            name: "Oregano (Seed)",
            type: ["seed/biji"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "seeds", 
            imgSrc: productOregano
        },
        {
            name: "Sesame (Seed)",
            type: ["seed/biji"],
            size: "250 gram atau sesuai permintaan",
            packaging: "sesuai permintaan",
            notes: "seeds", 
            imgSrc: productSesame
        },
    ]
    }, [])

    const [productList, setProductList] = useState(productListEn);


    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(productList);
    const handleSearch = (event:any) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = productList.filter((product:any) => 
        product.name.toLowerCase().includes(term) ||
        product.type.some((type:any) => type.toLowerCase().includes(term)) ||
        product.size.toLowerCase().includes(term) ||
        product.packaging.toLowerCase().includes(term) ||
        product.notes.toLowerCase().includes(term)
        );

        setFilteredProducts(filtered);
    };

    const handleFilter= (filterValue:string) => {
        const term = filterValue || "";

        const filtered = productList.filter((product:any) => 
            product.type.some((type:any) => type.toLowerCase().includes(term)) 
        );

        setFilteredProducts(filtered);
    };

    useEffect(()=>{
        if(defaultLanguage==="en"){
            setProductList(productListEn)
        } else {
            setProductList(productListIna)
        }
        setTimeout(()=>{
            return setFilteredProducts(productList);
        }, 0)
    }, [defaultLanguage, productListEn, productListIna, productList])


    return(
        <div className={`${windowWidthClass}-catalogs-screen-1`}>
            <AppNavbar additional={{navClass: "navbar-additional"}}/>
            <div className="screen-wrapper">
                <div className="wrapper-searchbar">
                    <h2>Product Catalogs</h2>
                    <input 
                    type="text" 
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="search here"/>
                    <div className="searchbar-menu">
                        <p className="linebar"
                            onClick={()=>handleFilter('')}
                        >all products</p>
                        <p className="linebar"
                            onClick={()=>handleFilter('whole')}
                        >whole</p>
                        <p className="linebar"
                            onClick={()=>handleFilter('powder')}
                        >powder</p>
                        <p
                            onClick={()=>handleFilter('seed')}
                        >seeds</p>
                    </div>
                </div>
                <div className="wrapper-cardbox">
                    {
                        filteredProducts.map((product:any, index:any)=>{
                            const {name, notes, size, packaging, imgSrc} = product;
                            return(
                                <div key={name+index} className="grid-item">
                                    <div className="card-product">
                                        <div className="card-wrapper-image">
                                            <img src={imgSrc} alt={name}/>
                                        </div>
                                        <div className="card-wrapper-details">
                                            <p className="card-name">{name}</p>
                                            <p className="notes"><b>available stock:</b> {notes}</p>
                                            <p className="size"><b>size:</b> {size}</p>
                                            <p className="packaging"><b>packaging:</b> {packaging}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default CatalogsScreen1;

